import React from 'react';
import './Home.css';

const Home = () => {
    return (
      <div className="text">
        <div className="big-text">FUCK ADIDAS</div>
        <a href="https://yeezy.com" className="subtext">power to the artist</a>
      </div>
    );
  };
  
  export default Home;
  